<template>
  <div>
    <div class="page-wrapper">
      <app-header
        @clicked="sidebarToggle"
        @mobileclicked="mobileToggle" />
      <div class="page-body-wrapper">
        <div
          class="page-sidebar"
          :class="[{ open: sidebarToggleVar }]">
          <app-sidebar />
        </div>
        <div class="page-body">
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <router-view class="view"></router-view>
          </transition>
        </div>
        <app-footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppHeader from 'components/layout/header';
import AppSidebar from 'components/layout/sidebar';
import AppFooter from 'components/layout/footer';

export default {
  name: 'mainpage',
  data() {
    return {
      mobileheaderToggleVar: false,
      sidebarToggleVar: false,
      resized: false
    };
  },
  components: {
    AppHeader,
    AppSidebar,
    AppFooter
  },
  computed: mapGetters(['menuItems', 'layout']),
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.resized = this.sidebarToggleVar;
    this.setTheme();
  },
  watch: {
    $route() {
      this.menuItems.filter((items) => {
        if (items.path === this.$route.path) {
          this.setActiveRoute(items);
        }
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === this.$route.path) {
            this.setActiveRoute(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === this.$route.path) {
              this.setActiveRoute(subSubItems);
            }
          });
        });
      });
    },
    sidebarToggleVar() {
      this.resized = this.width <= 991 ? !this.sidebarToggleVar : this.sidebarToggleVar;
    }
  },
  methods: {
    ...mapActions(['setTheme', 'setActiveRoute']),
    sidebarToggle(value) {
      this.sidebarToggleVar = !value;
    },
    mobileToggle(value) {
      this.mobileheaderToggleVar = value;
    },
    handleResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    }
  }
};
</script>
