<template>
  <div>
    <div :class="['page-wrapper', canvasClasses]" id="canvas-bookmark">
      <div class="page-main-header" :class="{ open: toggle_sidebar_var }">
        <div class="main-header-right row">
          <div class="main-header-left d-lg-none">
            <div class="logo-wrapper">
              <router-link :to="{ name: 'Home' }">
                <img src="assets/images/logo.svg" alt=""/>
              </router-link>
            </div>
          </div>
          <div class="mobile-sidebar">
            <div class="media-body text-right switch-sm">
              <label class="switch" v-on:click="toggleSidebar">
                <feather type="align-left"></feather>
              </label>
            </div>
          </div>
          <div class="nav-right col">
            <ul class="nav-menus" :class="{ open: mobile_toggle }">
              <li></li>
              <li class="onhover-dropdown">
                <a class="txt-dark"> <h6 class="language-header-container">{{ currentLanguage.name }}</h6></a>
                <ul class="language-dropdown onhover-show-div p-t-20 p-b-20">
                  <li v-for="language in languages" :key="language.locale" class="col-12">
                    <a @click="changeLanguage(language)" class="master-link row p-0 m-0">
                      <i :class='["flag-icon", flagIcon(language.flag)]'></i> {{ language.name }}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <router-link :to="{name: 'MessageList'}">
                  <feather type="message-circle"></feather>
                  <span v-if="totalUnread > 0" class="dot"></span>
                </router-link>
              </li>
              <li class="onhover-dropdown">
                <div class="media align-items-center">
                  <img
                    class="align-self-center pull-right img-50 rounded-circle"
                    :src="getImgUrl()"
                    alt="header-user"
                  />
                </div>
                <ul class="profile-dropdown onhover-show-div p-20">
                  <li>
                    <a href="#" @click="editProfile">
                      <feather type="user"></feather>{{ $tc('views.constants.editProfile') }}
                    </a>
                  </li>
                  <li @click="logout">
                    <a href="#"><feather type="log-out"></feather>{{ $tc('views.constants.logout') }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <div
              class="d-lg-none mobile-toggle pull-right"
              v-on:click="mobile_toggle = !mobile_toggle"
            >
              <feather type="more-horizontal"></feather>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let body = document.getElementsByTagName('body')[0];
import { mapGetters, mapActions } from 'vuex';
import { languages } from 'constants';

export default {
  name: 'Search',
  data() {
    return {
      languages: languages,
      terms: '',
      searchResult: false,
      searchResultEmpty: false,
      toggle_sidebar_var: false,
      clicked: false,
      rightclicked: false,
      mobile_toggle: false,
      mobile_search: false
    };
  },
  computed: mapGetters(['menuItems', 'currentLanguage', 'canvasClasses', 'totalUnread', 'currentUser']),
  methods: {
    ...mapActions(['searchTerm', 'switchLanguage', 'logout', 'getTotalUnread', 'setLayoutType']),
    getImgUrl() {
      return localStorage.getItem('Userinfo')
        ? localStorage.getItem('Userinfo').photoURL
        : require('assets/images/user/user.png');
    },
    search() {
      this.searchTerm(this.terms);
    },
    addFix() {
      body.classList.add('offcanvas');
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove('offcanvas');
      this.searchResult = false;
      this.terms = '';
    },
    toggleSidebar() {
      (this.toggle_sidebar_var = !this.toggle_sidebar_var),
      (this.clicked = !this.toggle_sidebar_var),
      this.$emit('clicked', this.clicked);
    },
    toggle_fullscreen() {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setWindowWidth() {
      let width = document.documentElement.clientWidth;
      let clicked = width < 1000 ? false : true;
      this.$emit('clicked', clicked);
    },
    flagIcon(icon) {
      return `flag-icon-${icon}`;
    },
    changeLanguage(locale) {
      this.switchLanguage(locale);
      let direction = locale.locale === 'ar' ? 'rtl' : 'ltr';
      this.setLayoutType(direction);
    },
    editProfile() {
      this.$router.push({ name: 'AdminEdit', params: { id: this.currentUser.id } });
    }
  },
  created() {
    this.getTotalUnread();
    if (window.innerWidth < 1000) {
      this.toggleSidebar();
    }
  },
  watch: {
    menuItems() {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.menuItems.length) this.searchResultEmpty = true;
      else this.searchResultEmpty = false;
    },
    '$route': {
      handler: function(id) {
        this.getTotalUnread();
      }, immediate: true
    }
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.setWindowWidth);
    });
  }
};
</script>
<style scoped>
  .language-header-container {
    min-width: 25px;
    text-align: center;
  }
</style>
