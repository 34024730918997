<template>
  <div>
    <div class="main-header-left d-none d-lg-block">
      <div class="logo-wrapper">
        <router-link to="/">
          <img src="~/assets/images/logo.svg" alt />
        </router-link>
      </div>
    </div>
    <div class="sidebar custom-scrollbar">
      <ul
        class="sidebar-menu"
        id="my-div"
        :style="[
          layout.settings.sidebar.type === 'horizontal-sidebar'
            ? layout.settings.layoutType === 'rtl'
              ? { 'margin-right': margin + 'px' }
              : { 'margin-left': margin + 'px' }
            : { margin: '0px' },
        ]"
      >
        <li
          class="left-arrow"
          :class="{
            'd-none':
              layout.settings.layoutType === 'rtl'
                ? hideLeftArrowRTL
                : hideLeftArrow,
          }"
          @click="
            layout.settings.sidebar.type === 'horizontal-sidebar' &&
            layout.settings.layoutType === 'rtl'
              ? scrollToLeftRTL()
              : scrollToLeft()
          "
        >
          <i class="fa fa-angle-left"></i>
        </li>
        <li
          v-for="(menuItem, index) in availableMenuItems"
          :key="index"
          :class="{ active: menuItem.active }"
        >
          <!-- Sub -->
          <a
            href="javascript:void(0)"
            class="sidebar-header"
            v-if="menuItem.type === 'sub'"
            @click="setNavActive(menuItem, index)"
          >
            <feather :type="menuItem.icon" class="top"></feather>
            <span>
              {{ itemTitle(menuItem) }}
              <span
                :class="'badge badge-pill badge-' + menuItem.badgeType"
                v-if="menuItem.badgeType"
              >
                {{ menuItem.badgeValue }}
              </span>
            </span>
            <i
              class="fa fa-angle-right pull-right"
              v-if="menuItem.children"
            ></i>
          </a>
          <!-- Link -->
          <router-link
            :to="{ name: menuItem.name }"
            class="sidebar-header"
            v-if="menuItem.type === 'link'"
            exact
          >
            <feather :type="menuItem.icon" class="top"></feather>
            <span>
              {{ itemTitle(menuItem) }}
              <span
                :class="'badge badge-' + menuItem.badgeType + ' ml-3'"
                v-if="menuItem.badgeType"
              >
                {{ menuItem.badgeValue }}
              </span>
            </span>
            <i
              class="fa fa-angle-right pull-right"
              v-if="menuItem.children"
            ></i>
          </router-link>
          <!-- External Link -->
          <a
            :href="{ name: menuItem.name }"
            class="sidebar-header"
            v-if="menuItem.type === 'extLink'"
            @click="setNavActive(menuItem, index)"
          >
            <feather :type="menuItem.icon" class="top"></feather>
            <span>
              {{ itemTitle(menuItem) }}
              <span
                :class="'badge badge-' + menuItem.badgeType + ' ml-3'"
                v-if="menuItem.badgeType"
              >
                {{ menuItem.badgeValue }}
              </span>
            </span>
            <i
              class="fa fa-angle-right pull-right"
              v-if="menuItem.children"
            ></i>
          </a>
          <!-- External Tab Link -->
          <a
            :href="{ name: itemTitle(menuItem) }"
            target="_blank"
            class="sidebar-header"
            v-if="menuItem.type === 'extTabLink'"
            @click="setNavActive(menuItem, index)"
          >
            <feather :type="menuItem.icon" class="top"></feather>
            <span>
              {{ itemTitle(menuItem) }}
              <span
                :class="'badge badge-' + menuItem.badgeType + ' ml-3'"
                v-if="menuItem.badgeType"
              >
                {{ menuItem.badgeValue }}
              </span>
            </span>
            <i
              class="fa fa-angle-right pull-right"
              v-if="menuItem.children"
            ></i>
          </a>
          <!-- 2nd Level Menu -->
          <ul class="sidebar-submenu" v-if="menuItem.children">
            <li
              v-for="(childrenItem, index) in menuItem.children"
              :key="index"
              :class="{ active: childrenItem.active }"
            >
              <!-- Sub -->
              <a
                href="javascript:void(0)"
                v-if="childrenItem.type === 'sub'"
                @click="setNavActive(childrenItem, index)"
              >
                <i class="fa fa-circle"></i>
                {{ itemTitle(childrenItem) }}
                <span
                  :class="
                    'badge badge-' + childrenItem.badgeType + ' pull-right'
                  "
                  v-if="childrenItem.badgeType"
                >
                  {{ childrenItem.badgeValue }}
                </span>
                <i
                  class="fa fa-angle-right pull-right"
                  v-if="childrenItem.children"
                ></i>
              </a>
              <!-- Link -->
              <router-link
                :to="{ name: childrenItem.name }"
                v-if="childrenItem.type === 'link'"
                exact
              >
              <i class="fa fa-circle"></i>
                {{ itemTitle(childrenItem) }}
                <span class="sidebar-header"
                >
                  <feather :type="childrenItem.icon" class="right"></feather>
                </span>
                <i
                  class="fa fa-angle-right pull-right"
                  v-if="childrenItem.children"
                ></i>
              </router-link>
              <!-- External Link -->
              <a
                :href="{ name: childrenItem.name }"
                v-if="childrenItem.type === 'extLink'"
              >
                <i class="fa fa-circle"></i>
                {{ childrenItem.title }}
                <span
                  :class="
                    'badge badge-' + childrenItem.badgeType + ' pull-right'
                  "
                  v-if="childrenItem.badgeType"
                >
                  {{ childrenItem.badgeValue }}
                </span>
                <i
                  class="fa fa-angle-right pull-right"
                  v-if="childrenItem.children"
                ></i>
              </a>
              <!-- External Tab Link -->
              <a
                :href="{ name: childrenItem.name }"
                target="_blank"
                v-if="childrenItem.type === 'extTabLink'"
              >
                <i class="fa fa-circle"></i>
                {{ childrenItem.title }}
                <span
                  :class="
                    'badge badge-' + childrenItem.badgeType + ' pull-right'
                  "
                  v-if="childrenItem.badgeType"
                >
                  {{ childrenItem.badgeValue }}
                </span>
                <i
                  class="fa fa-angle-right pull-right"
                  v-if="childrenItem.children"
                ></i>
              </a>
              <!-- 3rd Level Menu -->
              <ul
                class="sidebar-submenu horizontal-child-sub"
                v-if="childrenItem.children"
              >
                <li
                  v-for="(childrenSubItem, index) in childrenItem.children"
                  :key="index"
                >
                  <!-- Link -->
                  <router-link
                    :to="{ name: childrenSubItem.name }"
                    v-if="childrenSubItem.type === 'link'"
                    exact
                  >
                    <i class="fa fa-circle"></i>
                    {{ itemTitle(childrenSubItem) }}
                    <span
                      :class="
                        'badge badge-' +
                        childrenSubItem.badgeType +
                        ' pull-right'
                      "
                      v-if="childrenSubItem.badgeType"
                    >
                      {{ childrenSubItem.badgeValue }}
                    </span>
                    <i
                      class="fa fa-angle-right pull-right"
                      v-if="childrenSubItem.children"
                    ></i>
                  </router-link>
                  <!-- External Link -->
                  <a
                    :href="{ name: childrenSubItem.name }"
                    v-if="childrenSubItem.type === 'extLink'"
                  >
                    <i class="fa fa-circle"></i>
                    {{ childrenSubItem.title }}
                    <span
                      :class="
                        'badge badge-' +
                        childrenSubItem.badgeType +
                        ' pull-right'
                      "
                      v-if="childrenSubItem.badgeType"
                    >
                      {{ childrenSubItem.badgeValue }}
                    </span>
                    <i
                      class="fa fa-angle-right pull-right"
                      v-if="childrenSubItem.children"
                    ></i>
                  </a>
                  <!-- External Tab Link -->
                  <a
                    :href="{ name: childrenSubItem.name }"
                    target="_blank"
                    v-if="childrenSubItem.type === 'extTabLink'"
                  >
                    <i class="fa fa-circle"></i>
                    {{ childrenSubItem.title }}
                    <span
                      :class="
                        'badge badge-' +
                        childrenSubItem.badgeType +
                        ' pull-right'
                      "
                      v-if="childrenSubItem.badgeType"
                    >
                      {{ childrenSubItem.badgeValue }}
                    </span>
                    <i
                      class="fa fa-angle-right pull-right"
                      v-if="childrenSubItem.children"
                    ></i>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li
          class="right-arrow"
          :class="{
            'd-none':
              layout.settings.layoutType === 'rtl'
                ? hideRightArrowRTL
                : hideRightArrow,
          }"
          @click="
            layout.settings.sidebar.type === 'horizontal-sidebar' &&
            layout.settings.layoutType === 'rtl'
              ? scrollToRightRTL()
              : scrollToRight()
          "
        >
          <i class="fa fa-angle-right"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Sidebar',
  data() {
    return {
      width: 0,
      height: 0,
      margin: 0,
      hideRightArrowRTL: true,
      hideLeftArrowRTL: true,
      hideRightArrow: true,
      hideLeftArrow: true,
      menuWidth: 0
    };
  },
  computed: {
    ...mapGetters(['menuItems', 'availableMenuItems', 'layout', 'sidebar'])
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    setTimeout(() => {
      const element = document.getElementById('my-div');
      this.menuWidth = element.offsetWidth;
      this.menuWidth > window.innerWidth
        ? ((this.hideRightArrow = false), (this.hideLeftArrowRTL = false))
        : ((this.hideRightArrow = true), (this.hideLeftArrowRTL = true));
    }, 500);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.menuItems.filter((items) => {
      if (items.title === 'serviceProvider') {
        this.setBadgeValue(items);
      }
      if (items.path === this.$route.path)
        this.setActiveRoute(items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === this.$route.path)
          this.setActiveRoute(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === this.$route.path)
            this.setActiveRoute(subSubItems);
        });
      });
    });
  },
  watch: {
    '$route': {
      handler: function(id) {
        this.menuItems.filter((items) => {
          if (items.title === 'serviceProvider') {
            this.setBadgeValue(items);
          }
        });
      }, immediate: true
    }
  },
  methods: {
    ...mapActions(['setActiveRoute', 'setNavActive', 'setBadgeValue']),
    handleResize() {
      this.width = window.innerWidth - 310;
    },
    itemTitle(item) {
      return item.model ? this.$tc(`models.${item.title}.entity`, 2) : item.title;
    },
    scrollToRightRTL() {
      this.temp = this.width + this.margin;
      // Checking condition for remaing margin
      if (this.temp === 0) {
        this.margin = this.temp;
        this.hideRightArrowRTL = true;
      }
      else {
        this.margin += this.width;
        this.hideRightArrowRTL = false;
        this.hideLeftArrowRTL = false;
      }
    },
    scrollToLeftRTL() {
      // If Margin is reach between screen resolution
      if (this.margin <= -this.width) {
        this.margin += -this.width;
        this.hideLeftArrowRTL = true;
      }
      else {
        this.margin += -this.width;
        this.hideRightArrowRTL = false;
      }
    },
    scrollToLeft() {
      // If Margin is reach between screen resolution
      if (this.margin >= -this.width) {
        this.margin = 0;
        this.hideLeftArrow = true;
      }
      else {
        this.margin += this.width;
        this.hideRightArrow = false;
      }
    },
    scrollToRight() {
      this.temp = this.menuWidth + this.margin;
      // Checking condition for remaing margin
      if (this.temp < this.menuWidth) {
        this.margin = -this.temp;
        this.hideRightArrow = true;
      }
      else {
        this.margin += -this.width;
        this.hideLeftArrow = false;
      }
    }
  }
};
</script>
